<template>
  <div>
    <x-tab :tabList="tabList" @change="tabChange">
      <i class="tab-search-icon" @click="searchEvent"></i>
    </x-tab>
    <x-pull-scroll-view ref="scrollView" :top="0.52" bg="#F6F8FB" @scrollLoadData="scrollLoadData" @refresh="refresh" @scrollBarPos="scrollBarPos">
      <ul v-if="xhrData.length>0" class="list-box">
        <li v-for="(item,index) in xhrData" :key="index" class="list-item">
          <div class="list-item-info" @click="detailEvent(item.id);">
            <p class="list-item-title">{{item.orgName}}</p>
            <p class="list-item-name">维护人：{{item.maintainer}}</p>
          </div>
          <div class="list-item-opt" @click="visitEvent(item.id);">拜访</div>
        </li>
      </ul>
      <x-no-data v-if="ajaxEnd&&!isNextPage&&xhrData.length>0"></x-no-data>
      <x-no-data-bg v-if="ajaxEnd&&xhrData.length<=0&&pageNum<=1" :tip="'还没有任何内容快新建一条吧~'"></x-no-data-bg>
    </x-pull-scroll-view>
    <i class="add-icon" @click="addEvent"></i>
  </div>
</template>

<script>
import tab from "@/components/tab";
import pullScrollView from "@/components/pullScrollView";
import noData from "@/components/noData";
import noDataBg from "@/components/noDataBg";
import { page } from "@/script/mixins/page";
import { cachePage } from "@/script/mixins/cachePage";
import { pageCustomer, queryCustomerCount } from "@/api/customerCenter";

export default {
  name: "customerCenterIndex",
  data () {
    return {
      scrollHeight: 0,
      scrollPos: 0,
      isLeader: JSON.parse(sessionStorage.getItem("token")).isLeader,
      customerType: 1000,
      tabList: []
    };
  },
  mixins: [page, cachePage],
  created () {
    this.init();
  },
  methods: {
    init () {
      const configJson = JSON.parse(sessionStorage.getItem("configJson")).CustomerTypeEnum;
      Object.keys(configJson).forEach((key, index) => {
        this.tabList.push({
          id: parseInt(key),
          text: configJson[key],
          check: index == 0
        });
      });
      queryCustomerCount().then(xhr => {
        this.tabList[0].text = `${this.tabList[0].text}(${xhr.data.orgCount})`;
        this.tabList[1].text = `${this.tabList[1].text}(${xhr.data.dealerCount})`;
      });
      this.getDataList();
    },
    getDataList (isReset = true, callback = null) {
      pageCustomer({
        customerType: this.customerType,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(xhr => {
        document.title = "客户中心";
        const listData = xhr.data.records;
        this.isNextPage = listData.length >= this.pageSize;
        isReset && (this.xhrData = []);
        listData.map(item => {
          this.xhrData.push(item);
        });
        this.ajaxEnd = true;
        callback && callback();
      });
    },
    scrollBarPos (scrollBarTop) {
      this.scrollPos = scrollBarTop;
    },
    addEvent () {
      this.$router.push({
        path: `/customerCenter/baseInfo/edit/new/${this.customerType}`
      });
    },
    detailEvent (id) {
      this.$router.push({
        path: `/customerCenter/detail/${id}/${this.customerType}`
      });
    },
    searchEvent () {
      this.$router.push({
        path: `/searchPage/customer/${this.customerType}`
      });
    },
    visitEvent (id) {
      this.$router.push({
        path: `/visitRecord/edit/new/${id}`
      });
    },
    refresh (done) {
      this.pageNum = 1;
      this.ajaxEnd = false;
      this.getDataList(true, () => {
        done();
      });
    },
    tabChange (item) {
      this.customerType = item.id;
      this.pageNum = 1;
      this.ajaxEnd = false;
      this.getDataList();
    }
  },
  activated () {
    this.$refs.scrollView.setScrollTopPos(this.scrollPos);
  },
  watch: {
    $route (to) {
      if (this.$store.getters.getRefresh && to.name == this.$options.name) {
        queryCustomerCount().then(xhr => {
          this.tabList[0].text = `${this.tabList[0].text.split("(")[0]}(${xhr.data.orgCount})`;
          this.tabList[1].text = `${this.tabList[1].text.split("(")[0]}(${xhr.data.dealerCount})`;
        });
        // this.getDataList();
        this.$store.commit("setRefresh", false);
      }
    }
  },
  components: {
    [tab.name]: tab,
    [pullScrollView.name]: pullScrollView,
    [noData.name]: noData,
    [noDataBg.name]: noDataBg
  }
};
</script>

<style scoped>
.tab-search-icon {
  padding: 0 0.28rem;
  background: url('~@images/tab_search_icon.png') no-repeat center/0.16rem;
}
.list-box {
  width: 3.21rem;
  padding: 0 0.12rem;
  background-color: #ffffff;
  border-radius: 0.08rem;
  margin: 0.1rem auto 0;
}
.list-item {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 0.01rem #f6f8fb;
  padding: 0.16rem 0;
}
.list-box .list-item:last-of-type {
  border-bottom: 0;
}
.list-item-info {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  width: 2.8rem;
}
.list-item-info p {
  width: 100%;
  line-height: 1;
}
.list-item-info .list-item-title {
  font-size: 0.15rem;
  line-height: 0.18rem;
  color: #30353d;
  margin-bottom: 0.1rem;
}
.list-item-name {
  font-size: 0.13rem;
  color: #93969d;
}
.list-item-opt {
  display: flex;
  align-items: center;
  font-size: 0.13rem;
  color: #2b82f7;
}
</style>
